<template>
  <v-scale-transition>
    <panel
      :breadcrumbs="breadcrumbs"
      :title="title"
    >
      <v-card>
        <v-toolbar
          dense
          card
        >
          <v-menu
            v-model="menu"
            close-on-content-click
            transition="scale-transition"
            min-width="290px"
          >
            <v-text-field
              slot="activator"
              v-model="monthOf"
              prepend-icon="event"
              readonly
            />

            <v-date-picker
              v-model="monthOf"
              :max="maxDate"
              :allowed-dates="allowedMonths"
              min="2021-01"
              type="month"
              year-icon="date_range"
            />
          </v-menu>
          <!-- <v-tooltip bottom>
            <v-btn
              slot="activator"
              icon
              @click="showChart = !showChart"
            >
              <v-icon>{{ showChart ? 'cancel' : 'mdi-chart-bar' }}</v-icon>
            </v-btn>
            <span>{{ showChart ? 'Hide chart' : 'Show chart' }}</span>
          </v-tooltip> -->
        </v-toolbar>
        <!-- <v-slide-y-transition>
          <v-card-text v-show="showChart">
            <chart
              :options="chartOptions"
              :series="series"
              type="area"
            />
          </v-card-text>
        </v-slide-y-transition> -->
        <v-data-table
          v-model="selected"
          :headers="headers"
          :items="items"
          :loading="loading"
          :expand="expand"
          item-key="day"
          hide-actions
          class="mt-3 elevation-1"
        >
          <template #items="props">
            <tr
              :active="props.selected"
              @click="toggleSelection(props)"
            >
              <td class="text-xs-center">
                {{ props.item.day }}
              </td>
              <td class="text-xs-center info">
                {{ props.item.dealer }}
              </td>
              <td class="text-xs-center info">
                {{ props.item.subscriber }}
              </td>
            </tr>
          </template>
          <template #expand="props">
            <v-layout column>
              <v-flex xs10>
                <v-card-text color="darken-5">
                  <v-data-table
                    :headers="detailHeaders"
                    :items="props.item.detail"
                    item-key="dealerId"
                    class="elevation-1"
                    disable-initial-sort
                  >
                    <template #items="thisProps">
                      <tr>
                        <td class="text-xs-left">
                          {{ thisProps.item.dealerId }}
                        </td>
                        <td class="text-xs-left">
                          {{ thisProps.item.nama }}
                        </td>
                        <td class="text-xs-left">
                          {{ thisProps.item.introducer }}
                        </td>
                        <td class="text-xs-center">
                          {{ thisProps.item.admd }}
                        </td>
                        <td class="text-xs-center">
                          {{ thisProps.item.tier }}
                        </td>
                      </tr>
                    </template>
                  </v-data-table>
                </v-card-text>
              </v-flex>
            </v-layout>
          </template>
          <template slot="footer">
            <tr>
              <td class="text-xs-right">
                Total:
              </td>
              <td class="text-xs-center info">
                {{ totalDealer }}
              </td>
              <td class="text-xs-center info">
                {{ totalSubscriber }}
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-card>
    </panel>
  </v-scale-transition>
</template>

<script>
import { DateTime, Interval } from 'luxon'
import { createGetParams } from '@/rest'

const title = 'Monthly User Statistics'
// import apexcharts from '@/mixins/apexcharts'
export default {
  components: {
    Panel: () => import(/* webpackChunkName: "panel" */ '@/components/Panel'),
  },
  // mixins: [apexcharts],
  data () {
    return {
      expand: false,
      monthOf: null,
      month: null,
      showChart: false,
      today: DateTime.local().setZone('Asia/Kuala_Lumpur').toFormat('yyyy-LL-dd'),
      breadcrumbs: [
        {
          text: 'User Statistics', disabled: false, to: '/support/user_statistics',
        },
        {
          text: title, disabled: true,
        },
      ],
      items: [],
      selected: [],
      loading: false,
      detailHeaders: [
        {
          text: 'Dealer ID',
          align: 'left',
          sortable: true,
          value: 'dealerId',
        },
        {
          text: 'Name',
          align: 'left',
          sortable: true,
          value: 'nama',
        },
        {
          text: 'Introducer',
          align: 'left',
          sortable: true,
          value: 'introducer',
        },
        {
          text: 'Area Dealer/Master Dealer',
          align: 'center',
          sortable: true,
          value: 'admd',
        },
        {
          text: 'Tier',
          align: 'center',
          sortable: false,
          value: 'tier',
        },
      ],
      headers: [
        {
          text: 'Daily',
          align: 'center',
          sortable: true,
          value: 'day',
        },
        {
          text: 'Dealer',
          align: 'center',
          sortable: true,
          value: 'dealer',
        },
        {
          text: 'Subscriber',
          align: 'center',
          sortable: true,
          value: 'subscriber',
        },
      ],
      title: 'Monthly User Statistics',
      totalDealer: 0,
      totalSubscriber: 0,
      maxDate: '',
      menu: false,
    }
  },

  computed: {
    // series: function () {
    //   const series = [
    //     { name: 'Dealer', data: [] },
    //     { name: 'Subscriber', data: [] },
    //   ]

    //   for (let i = 0; i < this.filteredItems.length; i++) {
    //     const timestamp = DateTime.fromSQL(this.filteredItems[i].date).toMillis()
    //     series[0].data.push([timestamp, this.filteredItems[i].dealer])
    //     series[1].data.push([timestamp, this.filteredItems[i].susbcriber])
    //   }

    //   return series
    // },

  },

  watch: {
    xox: function (val) {
      this.onex = !val
    },
    monthOf: function (val) {
      this.getActivations(val)
    },
  },
  mounted: function () {
    const now = DateTime.local().setZone('Asia/Kuala_Lumpur')
    this.maxDate = this.monthOf = now.toFormat('yyyy-LL')
  //   this.chartOptions.chart.type = 'bar'
  //   this.chartOptions.chart.stacked = true
  //   this.chartOptions.xaxis.type = 'category'
  //   this.chartOptions.xaxis.category = [
  //     'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
  //     'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec',
  //   ]
  //   this.chartOptions.xaxis.labels.formatter = val => this.chartOptions.xaxis.category[val - 1]
  },
  methods: {
    allowedMonths: function (month) {
      const ym = month.split('-')
      const whatMonth = DateTime.local(Number(ym[0]), Number(ym[1])).setZone('Asia/Kuala_Lumpur')
      const fromDate = DateTime.local(2021, 5, 1).setZone('Asia/Kuala_Lumpur')
      const now = DateTime.local().setZone('Asia/Kuala_Lumpur')

      return Interval.fromDateTimes(fromDate, now).contains(whatMonth)
    },
    getActivations: function () {
      const params = createGetParams({ month: this.monthOf })
      this.$rest.get('getUserStatisticsMonthly.php', params)
        .then(response => {
          this.items = response.data.items
          this.totalDealer = response.data.totalDealer
          this.totalSubscriber = response.data.totalSubscriber
          console.log(response.data.items)
        }).catch(e => {
          // ignore
        })
    },
    getDailyNewDealer: function (day) {
      const param = { day }
      const callback = (element) => element.day === day
      const index = this.items.findIndex(callback)
      this.$rest.get('getDailyNewDealer.php', createGetParams(param))
        .then(function (response) {
          this.items[index].detail = response.data.item
        }.bind(this)).catch(error => {
          let message = error.message
          if (error.response) {
            message += ': ' + error.response.data
          }
          console.log(message)
        })
    },

    toggleSelection: function (item) {
      item.selected = !item.selected
      item.expanded = !item.expanded
      if (this.selected.length > 1) this.selected.shift()
      if (item.expanded) {
        this.getDailyNewDealer(item.item.day)
      }
    },
  },
}

</script>
